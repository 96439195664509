.GlpSwap {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.GlpSwap-content {
  display: flex;
  justify-content: space-between;
  border-bottom-left-radius: 0.8rem;
  border-bottom-right-radius: 0.8rem;
}

.GlpSwap .App-card,
.GlpSwap .App-box {
  padding: 20px;
  background: var(--section-bg);
  border-radius: 20px;
}

.Tab-title {
  letter-spacing: 0px;
  color: #ffffff;
  font-size: 3.41rem;
  font-weight: 500;
}

.Tab-description {
  letter-spacing: 0px;
  color: var(--text-primary);
  margin-top: 0.8rem;
}

.Tab-description span {
  color: #f89191;
}

.GlpSwap-from-token .Modal {
  align-items: flex-start;
  justify-content: flex-end;
}

.GlpSwap-from-token .Modal .Modal-content {
  width: 41.85rem;
}

.GlpSwap-from-token .Modal-content {
  position: absolute;
  width: 38.75rem;
  max-height: 100vh;
  overflow: hidden;
  border: none;
  border-radius: 0;
  top: 0;
  bottom: 0;
}
.GlpSwap-from-token .Modal-content .Modal-body {
  padding-right: 0.5rem;
  max-height: calc(100vh - 7.75rem);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  -webkit-overflow-scrolling: auto;
}

.GlpSwap-content .GlpSwap-stats-card,
.GlpSwap-content .GlpSwap-box {
  width: 100%;
}

.GlpSwap-content .GlpSwap-stats-card {
  max-width: 71.2rem;
  margin-right: 2rem;
}

.GlpSwap-content .GlpSwap-stats-card .App-card-divider {
  margin: 30px 0;
  background: rgba(255, 255, 255, 0.25);
}

.GlpSwap-content .App-card-content {
  gap: 15px;
}
.GlpSwap-stats-card .App-card-row .label {
  font-size: 16px;
  line-height: 21px;
  color: var(--text-primary);
  opacity: 1;
}
.GlpSwap-stats-card .App-card-row .value {
  display: flex;
  flex-direction: column;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
}
.GlpSwap-stats-card .App-card-row .value .value-price {
  color: var(--text-light);
}
.GlpSwap-content .GlpSwap-box {
  display: flex;
  flex-direction: column;
  max-width: 47.95rem;
}

.GlpSwap-box .Exchange-swap-option-tabs {
  margin: 0 0 3rem;
}
.GlpSwap-box .Exchange-swap-option-tabs .Tab-option {
  padding: 16px 20px;
}
.App-card-title-mark-icon {
  position: relative;
  display: flex;
}

.App-card-title-mark-icon > img:first-child {
  width: 50px;
  height: 50px;
}

.App-card-title-mark-icon .selected-network-symbol {
  position: absolute;
  bottom: 0;
  right: 0;
  border: 1px solid #1e1f37;
  border-radius: 50%;
}

.App-card-title-mark {
  display: flex;
  align-items: center;
  gap: 10px;
}

.App-card-title-mark > img {
  width: 50px;
  height: 50px;
}

.App-card-title-mark .App-card-title-mark-title {
  font-weight: 700;
  font-size: 30px;
  line-height: 39px;
  letter-spacing: 0px;
  color: #ffffff;
}

.App-card-title-mark .App-card-title-mark-subtitle {
  font-size: 1.2rem;
  line-height: 1.5rem;
  letter-spacing: 0px;
  color: var(--text-primary);
}

.GlpSwap-token-list {
  border-radius: 20px;
  background: var(--section-bg);
  padding: 2.5rem 1.5rem;
  text-align: left;
}

.GlpSwap-token-list-content {
  display: grid;
  padding: 1.5rem;
  padding-top: 0;
  text-align: left;
}

.GlpSwap-token-list .token-table {
  display: flex;
  flex-direction: column;
}
.GlpSwap-token-list .token-table thead tr,
.GlpSwap-token-list .token-table tbody tr {
  display: grid;
  grid-template-columns: 4fr 2fr 2.5fr 2.5fr 1.5fr 2.5fr 1.5fr 130px;
  align-items: center;
}

.GlpSwap-token-list .token-table th:last-child,
.GlpSwap-token-list .token-table td:last-child {
  width: auto;
}

.GlpSwap-token-list .token-table th:first-child,
.GlpSwap-token-list .token-table td:first-child {
  width: auto;
}

.GlpSwap-token-list .token-table td {
  padding: 3rem 1.5rem;
  display: block;
}

.GlpSwap-token-list .token-table th {
  padding: 0 1.5rem 1.7rem;
  display: block;
}
.GlpSwap-box .App-card-title {
  margin-bottom: 0.8rem;
}

.GlpSwap-box .Exchange-info-row {
  margin-top: 3rem;
  margin-bottom: 0;
  height: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.GlpSwap-box .Exchange-info-row .Exchange-info-label {
  margin-right: 0.8rem;
  color: var(--text-primary);
  opacity: 1;
}

.GlpSwap-cta {
  display: flex;
  width: 100%;
  margin: auto auto 0;
  text-align: center;
  padding-top: 0;
}

.GlpSwap-cta .Exchange-swap-button {
  margin: 0 auto;
  width: 100%;
  justify-content: center;
  min-height: 6rem;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
}

.token-table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  white-space: nowrap;
  font-size: 14px;
  line-height: 18px;
}

.token-grid {
  display: none;
}

.token-table td {
  padding: 3rem 2rem;
}

.token-table th {
  padding: 0 2rem 1.7rem;
}

/* .token-table td:nth-child(2),
.token-table th:nth-child(2) {
  width: 11rem;
}

.token-table td:nth-child(3),
.token-table th:nth-child(3) {
  width: 32rem;
}

.token-table td:nth-child(4),
.token-table th:nth-child(4) {
  width: 20rem;
}

.token-table td:nth-child(5),
.token-table th:nth-child(5) {
  width: 13rem;
} */

.token-table-price {
  font-weight: 500;
}

.token-table-amount {
  font-size: 1.4rem;
}

.token-table td {
  color: #ffffffde;
}

.token-table thead tr {
  font-size: 14px;
  line-height: 15px;
  border-bottom: 1px solid var(--border-light);
}
.token-table th:first-child,
.token-table td:first-child {
  padding-left: 0;
  width: 21.35rem;
}

.token-table th:last-child,
.token-table td:last-child {
  padding-right: 0;
  text-align: right;
  width: 15rem;
}

.token-table th {
  font-weight: normal;
  /* letter-spacing: 0.25px; */
  color: var(--text-primary);
}

.token-table .App-card-title-info {
  align-items: center;
}

.token-table .App-card-title-info-icon img {
  width: 50px;
  height: 50px;
}

.token-table .action-btn {
  width: 100%;
  justify-content: center;
  padding: 13px 20px;
}
.token-table tbody tr {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.token-table tbody tr:last-child {
  border-bottom: 0;
}
.cap-reached {
  margin-left: 0.8rem;
}

.AppOrder-ball-container {
  position: relative;
  z-index: 2;
}

.AppOrder-ball-icon {
  text-align: center;
  display: block;
  font-size: var(--font-lg);
  opacity: 0.7;
}

.AppOrder-ball {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 3.4rem;
  height: 3.4rem;
  left: 50%;
  margin-left: -1.7rem;
  top: -2.2rem;
  border-radius: 50%;
  user-select: none;
  background: rgba(217, 217, 217, 0.1);
  color: var(--text-gold);
  cursor: pointer;
  opacity: 0.85;
}
.AppOrder-ball:hover {
  opacity: 1;
}

@media screen and (max-width: 1260px) {
  .GlpSwap-token-list .token-table .App-card-title-info-icon img {
    width: 35px;
    height: 35px;
  }

  .GlpSwap-token-list .token-table .App-card-title-info {
    gap: 10px;
  }
}

@media screen and (max-width: 1180px) {
  .GlpSwap-token-list .token-table .App-card-title-info-icon img {
    width: 30px;
    height: 30px;
  }

  .GlpSwap-token-list .token-table td,
  .GlpSwap-token-list .token-table th {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .GlpSwap-token-list {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (max-width: 900px) {
  .GlpSwap-content {
    flex-wrap: wrap;
  }

  .App-card-title-mark > img {
    width: 35px;
    height: 35px;
  }

  .App-card-title-mark {
    gap: 6px;
  }

  .GlpSwap-content .GlpSwap-stats-card {
    margin: 0;
    margin-bottom: 3.1rem;
    max-width: 100%;
  }

  .GlpSwap-content .GlpSwap-box {
    margin: 0;
    max-width: 100%;
  }

  .GlpSwap-cta .Exchange-swap-button {
    margin-top: 3rem;
  }
}

@media (max-width: 1100px) {
  .GlpSwap-from-token .Modal {
    align-items: center;
    justify-content: center;
  }
  .GlpSwap-from-token .Modal .Modal-content {
    width: calc(100% - 32px);
    position: absolute;
    max-height: 80vh;
    border-radius: 20px;
    overflow: hidden;
    border: none;
    top: auto;
    bottom: auto;
  }
  .GlpSwap-from-token .Modal .Modal-content .Modal-body {
    overflow-y: auto;
    max-height: calc(80vh - 8.525rem);
    padding-right: 5px;
    margin: 1.5rem calc(1rem - 0.5rem) 1.5rem 1.5rem;
  }

  .GlpSwap-token-list {
    border: none;
    background: transparent;
    padding: 0;
  }

  .GlpSwap-token-list-content {
    padding: 0;
  }

  .GlpSwap-token-list .token-table {
    display: none;
  }

  .token-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1.5rem;
    margin-top: 0.8rem;
  }
  .App-card-title-mark .App-card-title-mark-title {
    font-size: 20px;
  }
  .App-card-title-mark-icon > img:first-child {
    width: 35px;
    height: 35px;
  }
}
@media (max-width: 510px) {
  .token-grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1.5rem;
    margin-top: 0.8rem;
  }
}
