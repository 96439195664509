.Buy-Crypto-Link-Box {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  padding: 20px 30px;
  align-items: center;
  width: 100%;

  background: linear-gradient(
    180deg,
    rgba(54, 61, 77, 0.25) 0%,
    rgba(49, 56, 69, 0.25) 49.48%,
    rgba(43, 48, 60, 0.25) 69.79%,
    rgba(54, 61, 77, 0.25) 100%
  );
  color: var(--text-primary);
  border-radius: 20px;
}

.Buy-Crypto-Link-Col {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.Buy-Crypto-Link-Action {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  gap: 6px;
  padding: 10px 24px;

  font-weight: 700;
  font-size: 13px;
  line-height: 13px;
  color: var(--btn-text);
  background: var(--btn-bg);
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
  border-radius: 30px;
  text-decoration: none;
  transition: background-color 0.3s;
}


.Buy-Crypto-Link-Heading {
  font-size: 18px;
  line-height: 20px;
  font-weight: 700;
}
.Buy-Crypto-Link-Text {
  font-size: 13px;
  line-height: 15px;
}

.Buy-Crypto-Link-Icon {
  width: 20px;
  height: 20px;
  transform: rotate(-90deg);
  transform-origin: center;
}

.Buy-Crypto-Link-Icon path {
  stroke: currentColor;
  stroke-width: 1.5px;
}

@media screen and (max-width: 768px) {
  .Buy-Crypto-Link-Box {
    padding: 15px 20px;
    gap: 10px;
  }

  .Buy-Crypto-Link-Action {
    font-size: 11px;
  }

  .Buy-Crypto-Link-Heading {
    font-size: 15px;
  }
  .Buy-Crypto-Link-Text {
    font-size: 12px;
  }
}
