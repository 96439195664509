.App-header-links {
  display: flex;
  align-items: center;
  gap: 8px;
}

.App-header-link-container a {
  position: relative;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;

  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  text-decoration: none;
  color: var(--text-primary);
  border-radius: 20px;
  transition: opacity 0.3s;
}

.App-header-link-container a:hover::after,
.App-header-link-container a.active::after {
  opacity: 1;
}

.App-header-link-container a::after {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: -1;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  opacity: 0;
  transition: opacity 0.3s ease 0s;
  background: linear-gradient(91.08deg, rgb(57, 64, 78) 1.23%, rgb(55, 63, 76) 100%);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 10px;
  content: "";
}

.App-header-link-icon {
  width: 14px;
  height: 14px;
  color: var(--text-primary);
}

@media (max-width: 1510px) {
  .App-header-links .App-header-link-container:last-child {
    display: none;
  }

  .App-header-link-container a {
    padding: 8px 10px;
    gap: 4px;
  }
}
