.App-header-network {
  position: relative;
  margin-left: 0.8rem;
  display: inline-flex;
  align-items: center;
  color: white;
}

.App-header-language {
  margin-left: 0;
}

.network-dropdown {
  display: inline-flex;
  gap: 8px;
}
.network-dropdown-btn {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  border: none;
  pointer-events: none;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background: transparent;
}

.network-dropdown-btn .network-dropdown-icon {
  width: 42px;
  height: 42px;
}
@media screen and (max-width: 600px) {
  .network-dropdown-btn {
    width: 34px;
    height: 34px;
  }

  .network-dropdown-btn .network-dropdown-icon {
    width: 34px;
    height: 34px;
  }
}
.network-dropdown-toggle {
  background: var(--text-primary);
  color: var(--btn-text);
}
.network-dropdown-items.menu-items {
  min-width: 17rem;
  top: 6rem;
  padding-bottom: 0.8rem;
  width: auto;
}
.network-dropdown-items:last-child {
  padding-bottom: 0;
}

.network-dropdown-divider {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.network-dropdown:hover {
  background: transparent;
  cursor: pointer;
}
.network-dropdown:active {
  background: transparent;
}

.network-dropdown-icon {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.language-popup .Modal-content {
  width: 32rem;
}

.menu-item-group {
  display: flex;
  align-items: center;
}

.network-dropdown-menu-item {
  display: flex;
  align-items: center;
  padding: 1.2rem 2rem;
  justify-content: space-between;
}
.last-dropdown-menu {
  margin-bottom: 0.8rem;
}
.network-popup .Modal-content {
  width: 100%;
  max-width: 29.6rem;
  margin: auto;
}
.network-option {
  cursor: pointer;
  background: transparent;
  border: 1px solid var(--border-light);
  border-radius: 25px;
  padding: 0.8rem 1.5rem;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: space-between;
  margin-bottom: 0.8rem;
}
.network-option:last-child {
  margin-bottom: 0;
}

.network-option img {
  width: 2.5rem;
  margin-right: 1.5rem;
}

.network-option span {
  font-size: var(--font-base);
  letter-spacing: 0.29px;
  color: #fff;
}

.network-option-img-label {
  font-size: var(--font-md);
  line-height: 2rem;
  letter-spacing: 0.29px;
  color: #fff;
}

.dropdown-label {
  color: rgba(255, 255, 255, 0.5);
  padding: 0.8rem;
  font-size: 1.6rem;
}

.language-popup .Modal-body {
  display: grid;
  grid-gap: 1rem;
  margin: 1.25rem 0rem;
}

.language-modal-item {
  cursor: pointer;
}

.menu-item-icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 20px;
}

.network-dropdown-headings {
  padding: 0.8rem 0.8rem 0.4rem 0.8rem;
  opacity: 0.7;
  font-size: 1.25rem;
}

.network-dropdown-item-label {
  margin-left: 0.8rem;
  font-size: large;
  white-space: nowrap;
}

.network-dropdown-list {
  display: grid;
  grid-template-columns: 1fr;
}

.menu-item:hover .menu-item-label {
  color: #eee;
}

.active-dot {
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 50%;
}
.active-dot.Arbitrum {
  background-color: #4275a8;
}
.active-dot.Avalanche {
  background-color: #e84142;
}
.network-dropdown-label {
  font-size: 1.25rem;
  margin-bottom: 0.8rem;
  opacity: 0.7;
}

.more-options {
  margin-top: 1rem;
}

@media (max-width: 1033px) {
  .App-header-language {
    margin-left: 0;
  }
}
@media (max-width: 450px) {
  .App-header-language {
    margin-left: 0;
  }
  .network-dropdown > button {
    padding: 0 0.5rem;
  }
  .network-dropdown.homepage-header > button {
    padding: 0 0.8rem;
  }
}

.App-header-network .menu-items {
  min-width: 180px;
  border-radius: 20px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: calc(100% + 20px);
  right: 0;
  z-index: 100;
  background: var(--panel-light-bg);
  box-shadow: rgba(0, 0, 0, 0.14) 0px 20px 40px, rgba(0, 0, 0, 0.094) 0px 10.125px 17.4375px,
    rgba(0, 0, 0, 0.07) 0px 4px 6.5px, rgba(0, 0, 0, 0.047) 0px 0.875px 2.3125px;
  backdrop-filter: blur(15px);
}
.App-header-network .network-dropdown-list {
  gap: 10px;
}

.App-header-network .menu-item {
  padding: 10px 20px;

  font-size: 14px;
  line-height: 18px;
  border-radius: 22px;
}

.App-header-network .network-dropdown-list .menu-item:first-child:hover {
  background-color: #1969ff;
}

.App-header-network .network-dropdown-list .menu-item:nth-child(2):hover {
  background-color: #f0b90b;
}
