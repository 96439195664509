.Referrals {
  padding-top: 4.65rem;
}

.section-center {
  margin: 0 auto;
  text-align: center;
}
.mt-large {
  margin-top: 3.1rem;
}
.mt-medium {
  margin-top: 1.5rem;
}
.referral-tab-container {
  margin: 1.5rem auto 3.1rem auto;
}
.referral-card {
  position: relative;
  padding: 2.4rem;
  border-radius: 1.3rem;
  max-width: 59.2rem;
  background: linear-gradient(
    180deg,
    rgba(54, 61, 77, 0.25) 0%,
    rgba(49, 56, 69, 0.25) 49.48%,
    rgba(43, 48, 60, 0.25) 69.79%,
    rgba(54, 61, 77, 0.25) 100%
  );
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.05);
  border: 1px solid #2a2b3d;
  border-radius: 5px;
  margin-top: 2rem;
}
.referral-card .title {
  font-weight: 500;
  font-size: var(--font-lg);
  line-height: 3.1rem;
  color: #ffffff;
  padding: 0.775rem 0;
  margin: 0;
}
.referral-card .sub-title {
  font-size: 1.8rem;
  line-height: 2.5rem;
  letter-spacing: 0px;
  color: #fff;
  margin-top: 1.1625rem;
}
.referral-card .card-action {
  margin-top: 3.1rem;
}

.referral-card .card-action input {
  border: 1px solid rgba(255, 255, 255, 0.2);
  font-size: var(--font-base);
  width: 100%;
  box-sizing: border-box;
  margin-right: 1.5rem;
}
.referral-body-container,
.rebate-container,
.rebate-container .list,
.referral-body-container .list {
  margin-top: 2rem;
}
.reward-history {
  margin-top: 3.1rem;
}
.active-referral-code .edit {
  display: flex;
  align-items: center;
  justify-content: center;
}
.active-referral-code .edit span {
  margin-right: 1.5rem;
}
.active-referral-code .edit svg {
  cursor: pointer;
}
.active-referral-code .edit svg:hover {
  opacity: 0.75;
}
.active-referral-code .tier {
  margin-top: 0.775rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.active-referral-code .tier span {
  font-size: var(--font-sm);
  color: var(--text-primary);
  margin-right: 0.775rem;
}
.active-referral-code .tier a {
  text-decoration: none;
  font-size: 0;
}
.mb-sm {
  margin-bottom: 1.5rem;
}
.edit-referral-modal {
  /* width: 31rem; */
}
.referral-code-edit span {
  margin-right: 0.775rem;
}
.referral-code-edit svg {
  opacity: 0.75;
  cursor: pointer;
}

.referral-code-edit svg:hover {
  opacity: 1;
}
.create-referrar-code {
  text-align: center;
  max-width: 50rem;
  margin: 3.1rem auto;
}
.transparent-btn {
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  padding: 0.75rem 1.25rem;
  color: white;
  font-size: var(--font-sm);
  border: 1px solid var(--border-light);
  cursor: pointer;
  border-style: none;
  background: linear-gradient(
    180deg,
    rgba(54, 61, 77, 0.25) 0%,
    rgba(49, 56, 69, 0.25) 49.48%,
    rgba(43, 48, 60, 0.25) 69.79%,
    rgba(54, 61, 77, 0.25) 100%
  );
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
  border-radius: 30px;
}

.transparent-btn:hover {
  background: var(--panel-light-bg);
}

.transparent-btn:active {
  background: var(--panel-light-bg);
}

.referral-table-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-size: 20px;
  line-height: 20px;
}
.referral-table-header .title {
  margin: 0;
  font-size: 20px;
  line-height: 20px;
  font-weight: 700;
}
.referral-table-header .sub-title {
  color: var(--text-primary);
  font-size: var(--font-sm);
  margin-left: 0.775rem;
}

.referral-table-header .ml-small {
  margin-left: 0.775rem;
}
.create-referrar-code form {
  display: flex;
  align-items: inherit;
  justify-content: center;
  margin: 3.1rem 0;
}
.create-referrar-code form input {
  border: 1px solid #1e2136;
  border-radius: 5px;
  padding: 1.1625rem;
  font-size: var(--font-base);
  width: 40rem;
}
.create-referrar-code form button {
  margin-left: 1.5rem;
}

.referral-stats {
  margin: 0 auto;
  display: grid;
  grid-gap: 1.5rem;
  grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
}

.text-center {
  text-align: center;
}

.info-card {
  background: var(--panel-light-bg);
  border: 1px solid #1e2136;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.info-card .label {
  font-size: var(--font-md);
  line-height: 2.1rem;
  font-weight: normal;
  letter-spacing: 0px;
  color: #ffffff;
  margin-bottom: 1.5rem;
  text-align: center;
}
.info-card .data {
  font-size: var(--font-lg);
  font-weight: normal;
  text-align: center;
  padding: 0;
  margin: 1.5rem 0;
}
.info-card .info-card-question-icon {
  vertical-align: middle;
  font-size: var(--font-sm);
  opacity: 0.8;
}

.table-referral-code {
  align-items: center;
  display: flex;
}

.table-referral-code .referral-text {
  margin-right: 0.775rem;
  font-family: monospace;
}

.table-referral-code .referral-code-icon {
  margin-right: 1rem;
  cursor: pointer;
  display: inline-flex;
}

.table-referral-code .referral-code-icon:hover {
  opacity: 0.75;
}

.table-referral-code .info {
  margin-left: 0.775rem;
}

.referral-table {
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
  font-size: var(--font-base);
}
.referral-table th {
  font-weight: normal;
  letter-spacing: 0.25px;
  color: var(--text-light);
}
.referral-table th,
.referral-table td {
  padding: 0.625em;
  text-align: left;
}

.table-referral-code p {
  margin: 0;
  margin-left: 0.775rem;
  padding: 0;
}
.error {
  margin: 0;
  padding: 0.775rem 0 1.5rem;
  color: #fa3c58;
  font-size: var(--font-sm);
}

.table-wrapper {
  display: block;
  width: 100%;
  font-size: var(--font-base);
}

.empty-message {
  margin: 4.65rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
  border: 1px solid #1e2136;
  border-radius: 5px;
  background: var(--panel-bg);
  font-size: var(--font-base);
}
.confirm-checkbox .Checkbox {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr auto;
  direction: rtl;
  font-size: var(--font-sm);
  margin-bottom: 1.5rem;
}
.referrals-tab-container {
  width: 100%;
  max-width: 277px;
}

.referral-body-container .card, 
.rebate-container .card {
  border-radius: 20px;
  background: var(--section-bg);
}

.referral-body-container .card-header,
.rebate-container .card-header {
  margin: 0px;
  padding: 20px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 20px 20px 0px 0px;
  min-height: 75px;
}

.referrals-option-tabs .Tab-option {
  padding: 13px 20px !important;
}

.referrals-content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
  width: 100%;
  margin-top: 2rem;
}

.referral-stats .referrals-stats-card {
  border-radius: 20px;
  background: var(--section-bg);
  padding: 20px;
}

.empty-message {
  background: var(--section-bg);
}

.referral-stats .referrals-stats-card .App-card-title {
  font-size: 20px;
  line-height: 20px;
}

.referral-stats .referrals-stats-card .App-card-content {
  margin-top: 30px;
  justify-content: center;
  font-size: 25px;
  font-weight: 400;
  line-height: 32.5px;
}

@media (max-width: 800px) {
  .referral-table {
    width: auto;
    margin-bottom: 1.5rem;
  }
  .table-wrapper {
    overflow-x: auto;
  }
  .table-head {
    white-space: nowrap;
  }
}
@media (max-width: 500px) {
  .referrals-tab-container {
    max-width: 100%;
}
  .referral-table-header .sub-title {
    display: block;
    margin-left: 0;
    margin-top: 2px;
  }
}
