.buy-input .InputSection-static-input {
  font-size: var(--font-lg);
}

.buy-input.Exchange-swap-section {
  min-height: 9.2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.buy-input .Exchange-swap-input-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.buy-input .Exchange-swap-max {
  right: 0;
}

.buy-input .TokenSelector-caret {
  margin: 0;
  margin-right: -0.5rem;
}

.buy-input .PositionEditor-token-symbol {
  display: flex;
  align-items: center;
  margin-left: 1.4rem;
}

.buy-input .Exchange-swap-section-top {
  padding-bottom: 0;
}

.buy-input .selected-token {
  display: flex;
  align-items: center;
  gap: 10px;

  font-weight: 700;
  font-size: 30px;
  line-height: 39px;
  letter-spacing: 0.02em;
}

.buy-input .selected-token > img {
  width: 35px;
  height: 35px;
}

.buy-input .Exchange-swap-balance {
  color: white;
}
