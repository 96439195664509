.Add-Token-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  text-decoration: none;
  padding: 7px 24px;
  background: var(--section-bg);
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
  border-radius: 30px;
  color: var(--text-primary);
  margin: 0;
  gap: 10px;
  transition: background-color 0.3s;
}
.Add-Token-btn:hover {
  background: var(--header-active-link);
}

.Add-Token-text {
  margin: 0;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
}

.Add-Token-icon {
  width: 30px;
  height: 30px;
}

@media screen and (max-width: 600px) {
  .Add-Token-btn {
    padding: 10px 12px;
  }

  .Add-Token-icon {
    width: 20px;
    height: 20px;
  }
}