.address-btn {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: none;
  padding: 11px 14px !important;
  background: #1a243f;
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
  color: var(--text-primary);
}

.user-avatar {
  display: flex;
}

.user-address {
  margin-left: 1.2rem;
  margin-right: 1.2rem;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: var(--text-primary);
}

@media screen and (max-width: 600px) {
  .user-address {
    margin-left: 1.2rem;
    margin-right: 1.2rem;
  }

  .App-header-user-address .menu-items {
    top: 5rem;
  }
}

.menu-items {
  position: absolute;
  right: 0;
  top: 5.5rem;
  min-width: 18rem;
  width: 100%;
  transform-origin: top right;
  border-radius: 0.8rem;
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
  list-style: none;
  cursor: pointer;
  outline: none;
  overflow: hidden;
  z-index: 1000;
}
.menu-item {
  display: flex !important;
  align-items: center;
  color: var(--text-primary);
  padding: 0.9rem 1.5rem;
  font-weight: 400;
  font-size: 13px;
  line-height: 17px;
  text-decoration: none;
  transition: background-color 0.3s;
}
.menu-item:hover {
  background: rgba(255, 255, 255, 0.1);
  opacity: 1;
  color: var(--text-primary);
}
.menu-item > svg {
  color: var(--text-accent-dark);
}
.menu-item > img {
  width: 16px;
  height: 16px;
}
.menu-item > p {
  margin: 0px;
  padding-left: 1rem;
}
.menu-item > a {
  display: inline-flex;
}

@media screen and (max-width: 600px) {
  .address-btn {
    display: flex;
    justify-content: space-between;
    padding: 7px 10px !important;
  }
}
