.chart-token-selector {
  padding: 0 10px !important;
  display: flex !important;
  align-items: center;
  gap: 10px;
}

.chart-token-selector--current {
  font-size: 2.2rem;
  font-weight: 700;
  color: white;
}
.chart-token-selector-logo {
  position: relative;
  width: 40px;
  height: 40px;
  z-index: 2;
  background: #1d1d1f;
  border-radius: 50%;
}
.chart-token-menu-items.menu-items {
  width: 20.4rem;
  top: 6rem;
  border: 1px solid var(--border-light);
  right: unset;
  left: 0;
  z-index: 2;
}

.chart-token-menu-items .menu-item {
  font-size: var(--font-sm);
  height: 3.4rem;
  color: var(--text-primary);
}

@media screen and (max-width: 1300px) {

  .chart-token-selector--current {
    font-size: 2rem;
  }

  .chart-token-selector-logo {
    width: 24px;
    height: 24px;
  }
}

@media screen and (max-width: 600px) {
  .chart-token-selector {
    padding: 0 !important;
  }

  .chart-token-selector--current {
    font-size: 1.6rem;
  }

  .chart-token-selector-logo {
    width: 24px;
    height: 24px;
  }
}
