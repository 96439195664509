.PricesCarousel-container {
  --slides-in-view: 1;
  --slide-height: 20px;
  --iteration-time: 10s;
}

@keyframes slide56 {
  0% {
    transform: translateY(0);
  }
  17% {
    transform: translateY(0);
  }
  20% {
    transform: translateY(calc(-1 * var(--slide-height)));
  }
  37% {
    transform: translateY(calc(-1 * var(--slide-height)));
  }
  40% {
    transform: translateY(calc(-2 * var(--slide-height)));
  }
  57% {
    transform: translateY(calc(-2 * var(--slide-height)));
  }
  60% {
    transform: translateY(calc(-3 * var(--slide-height)));
  }
  77% {
    transform: translateY(calc(-3 * var(--slide-height)));
  }
  80% {
    transform: translateY(calc(-4 * var(--slide-height)));
  }
  97% {
    transform: translateY(calc(-4 * var(--slide-height)));
  }
  100% {
    transform: translateY(calc(-5 * var(--slide-height)));
  }
}

@keyframes slide250 {
  0% {
    transform: translateY(0);
  }
  14% {
    transform: translateY(0);
  }
  17% {
    transform: translateY(calc(-1 * var(--slide-height)));
  }
  31% {
    transform: translateY(calc(-1 * var(--slide-height)));
  }
  34% {
    transform: translateY(calc(-2 * var(--slide-height)));
  }
  48% {
    transform: translateY(calc(-2 * var(--slide-height)));
  }
  51% {
    transform: translateY(calc(-3 * var(--slide-height)));
  }
  65% {
    transform: translateY(calc(-3 * var(--slide-height)));
  }
  68% {
    transform: translateY(calc(-4 * var(--slide-height)));
  }
  82% {
    transform: translateY(calc(-4 * var(--slide-height)));
  }
  85% {
    transform: translateY(calc(-5 * var(--slide-height)));
  }
  97% {
    transform: translateY(calc(-5 * var(--slide-height)));
  }
  100% {
    transform: translateY(calc(-6 * var(--slide-height)));
  }
}

.PricesCarousel {
  display: flex;
  flex-direction: column;
  height: var(--slide-height);
  overflow: hidden;
}

.PricesCarousel-track {
  display: flex;
  flex-direction: column;
  height: calc(var(--slides-in-view) * var(--slide-height));
  margin: 0 auto;
  animation-duration: var(--iteration-time);
  animation-iteration-count: infinite;
}

.PricesSlide {
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;
  height: var(--slide-height);
  box-sizing: border-box;
}

.PriceWrapper {
  display: flex;
  align-items: center;
  margin-right: 10px;
  border-radius: 30px;
  min-width: 75px;
  color: var(--text-primary);
  transition: color 0.3s;
  cursor: pointer;
}

.TokenLogoWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;
  margin-right: 10px;
}

.TokenLogo {
  width: 18px;
  height: 18px;
}

@media (max-width: 720px) {
  .TokenLogoWrapper {
    width: 18px;
    height: 18px;
  }

  .TokenLogo {
    width: 18px;
    height: 18px;
  }
}
@media (max-width: 1200px) {
  .TokenLogoWrapper {
    width: 32px;
    height: 32px;
  }

  .TokenLogo {
    width: 32px;
    height: 32px;
  }
}
