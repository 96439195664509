.App {
  position: relative;
  overflow: hidden;
  min-height: 100%;
  font-size: var(--font-base);
}

.App-bg {
  position: absolute;
  top: -100px;
  left: 0px;
  width: 100%;
  height: 750px;
  pointer-events: none;
  overflow: hidden;
}

.App-bottom-bg {
  position: fixed;
  bottom: 0;
  right: 0;
  object-fit: cover;
  opacity: 0.8;
  z-index: 1;
  pointer-events: none;
}

.App-bg-image {
  position: absolute;
  top: 0px;
  left: 0px;
  object-fit: cover;
  width: 100%;
  min-height: 750px;
  opacity: 0.25;
}

.App-content header {
  position: relative;
  z-index: 800;
}

.App-box,
.App-card,
.App-card-primary,
.App-box-highlight,
.App-box-solid {
  position: relative;
  border-radius: 5px;
  background: var(--panel-bg);
  font-size: var(--font-base);
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.05);
}

.App-card-bottom-placeholder {
  visibility: hidden;
}

.App-card-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1.5rem;
  padding-bottom: 1.8rem;
}

.App-card-bottom .App-card-divider,
.App-card-bottom-placeholder .App-card-divider {
  margin-bottom: 1.8rem;
}

.App-card-long {
  background: var(--panel-bg);
  margin-bottom: 2.4rem;
}

.App-card-long-content {
  display: flex;
  padding: 1.6rem 0;
}

.App-card-long_sub {
  width: 20%;
  padding: 0 1.6rem;
}

.App-card-long_sub__info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 0.8rem;
}

.App-card-long_sub__info___title {
  font-size: var(--font-md);
  line-height: 2.1rem;
  font-weight: normal;
  letter-spacing: 0px;
  color: #ffffff;
}

.App-card-long_sub__info___subtitle {
  font-size: 1.2rem;
  line-height: 1.5rem;
  font-weight: normal;
  letter-spacing: 0px;
  color: var(--text-primary);
}

.App-card-long-sub-left {
  display: flex;
}

.App-card-long_sub-icon {
  display: flex;
}

.App-card-long_sub__iconlist .App-card-long_sub__iconlist___icon {
  margin-left: 0.8rem;
}

.App-card-long_sub__iconlist .App-card-long_sub__iconlist___icon img {
  filter: grayscale(1);
}

.App-card-long_sub__iconlist .App-card-long_sub__iconlist___icon img:hover {
  filter: unset;
}

.App-card-long_sub__iconlist {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.App-card-divider-vertical {
  width: 1px;
  height: 8.8rem;
  background: #a0a3c4;
}

.App-card-long_sub:first-child {
  display: flex;
  align-items: center;
}

.App-card-long_sub__title {
  color: var(--text-primary);
  font-size: var(--font-sm);
  line-height: 1.8rem;
  font-weight: normal;
  letter-spacing: 0.25px;
  margin-bottom: 0.8rem;
}

.App-card-long_sub__subtitle {
  letter-spacing: 0.36px;
  color: white;
  font-size: 2rem;
  line-height: 2.6rem;
  font-weight: normal;
}

.App-card-title-block {
  display: flex;
  justify-content: space-between;
  padding: 1.6rem;
}

.App-card-title-info-icon {
  display: flex;
}

.App-card-title-info {
  display: flex;
  align-items: center;
  gap: 15px;
}

.App-card-info-title {
  font-size: var(--font-md);
  line-height: 2.1rem;
  font-weight: 700;
  letter-spacing: 0px;
  color: #ffffff;
}

.App-card-info-subtitle {
  font-size: 1.6rem;
  line-height: 2.1rem;
  font-weight: normal;
  letter-spacing: 0px;
  color: var(--text-primary);
}

.App-card-title-iconlist {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 0.8rem;
  grid-row-gap: 0.8rem;
}

.App-card-title-iconlist___icon img {
  filter: grayscale(1);
}

.App-card-title-iconlist___icon img:hover {
  filter: unset;
}

.Wallet-btn {
  display: flex;
  align-items: center;
  background: rgba(255, 255, 255, 0.15);
  width: 100%;
  border: none;
  border-radius: 10px;
  position: relative;
  color: white;
  font-size: 1.6rem;
  display: grid;
  grid-template-columns: auto 1fr;
  text-align: left;
  padding: 10px 20px;
  transition: background-color 0.3s;
}
.Wallet-btn:hover {
  background: rgba(255, 255, 255, 0.3);
  border-color: rgba(255, 255, 255, 0.3);
}
.Wallet-btn:active {
  background: rgba(255, 255, 255, 0.3);
}

.Wallet-btn:not(:last-child) {
  margin-bottom: 1.05rem;
}

.MetaMask-btn img {
  display: inline-block;
  width: 2.4rem;
  height: 2.4rem;
  margin-right: 1.7rem;
  margin-left: 0.15rem;
}

.CoinbaseWallet-btn img {
  display: inline-block;
  height: 2.4rem;
  margin-right: 1.7rem;
}

.WalletConnect-btn img {
  display: inline-block;
  height: 2.4rem;
  margin-right: 1.8rem;
}

button.App-connect-wallet {
  cursor: pointer;
  display: inline-block;
  border: none;
  font-size: var(--font-sm);
  line-height: 2rem;
  font-weight: 500;
  padding: 0.9rem 2.4rem;
  height: 3.6rem;
  color: #17182c;
  background: #03cfcd 0% 0% no-repeat padding-box;
  border-radius: 0.4rem;
}

button.App-connect-wallet:hover {
  opacity: 1;
}

.mobile-menu-divider {
  height: 1px;
  background: #4e516a;
}

.App-card-divider {
  height: 1px;
  background: var(--border-light);
  margin: 1.5rem 0rem;
}

.App-box-solid {
  background: linear-gradient(90deg, rgba(20, 21, 38, 1) 0%, rgba(25, 27, 47, 1) 100%);
}

.Page {
  max-width: 108.5rem;
  margin: auto;
  padding-top: 4.65rem;
}

.Page-content {
  padding: 4.65rem;
  padding-top: 1.5rem;
}

.section-title-block {
  display: flex;
  margin-bottom: 2.325rem;
  max-width: 58.4rem;
  width: 100%;
}

.page-main-content .section-title-block {
  margin-top: 6.2rem;
}

.section-title-icon {
  display: flex;
  align-items: center;
}

.section-title-icon img {
  display: none;
}

.section-title-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.section-title-content .section-title-content__title {
  font-size: var(--font-lg);
  line-height: 3.1rem;
  color: white;
  letter-spacing: 0px;
}

.section-title-content .section-title-content__description {
  font-size: 1.8rem;
  line-height: 2.3rem;
  letter-spacing: 0px;
  color: var(--text-primary);
  margin-top: 0.4rem;
  max-width: 62rem;
}

.section-title-content .section-title-content__description span {
  cursor: pointer;
  color: white;
}

.Page-title-section {
  padding-left: 4.65rem;
  padding-right: 1.5rem;
  margin-top: 1.5rem;
  position: relative;
}

@media screen and (max-width: 600px) {
  .Page-title-section {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

.Page-title-section:first-child {
  margin-top: 0rem;
}

.Page-title {
  font-size: var(--font-xl);
  font-weight: 500;
  margin-bottom: 0.8rem;
  font-weight: bold;
}

.Page-description {
  color: var(--text-primary);
  line-height: 2.15rem;
  font-size: var(--font-base);
}

.Page-description span,
.Page-description a {
  text-decoration: underline;
  cursor: pointer;
  display: inline-flex;
  color: inherit;
}

.Page-description span:hover {
  color: #fff;
  opacity: 0.9;
}

.Page-description span img,
.Page-description a img {
  margin-left: 0.4rem;
}

.App-hero {
  position: relative;
  text-align: center;
  padding-top: 6.2rem;
  margin-bottom: 3.1rem;
}

.App-hero-primary {
  font-size: 4.65rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.App-note {
  text-align: center;
  font-weight: bold;
  font-size: var(--font-sm);
  padding: 0 3.1rem;
}

.App-card {
  padding: 2rem 5rem;
  border: 1px solid #1e2136;
  border-radius: 5px;
  font-size: var(--font-base);
}

.App-card-title {
  min-height: 50px;
  font-weight: 700;
  font-size: 30px;
  line-height: 39px;
  letter-spacing: 0px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.App-card-description {
  margin: 1.5rem 0 0;
  font-weight: 400;
  font-size: 13px;
  line-height: 17px;
}

.App-card-title > img {
  width: 50px;
  height: 50px;
}

.App-card-title-small {
  font-size: var(--font-md);
  margin-bottom: 1.5rem;
}
.App-card-content-row {
  display: flex;
  align-items: center;
  gap: 20px;
}
.App-card-content-row .App-card-content {
  flex-grow: 1;
}
.App-card-content {
  display: grid;
  grid-row-gap: 1.5rem;
}

.App-card-row {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-column-gap: 1.5rem;
  padding: unset;
  align-items: center;
}

.App-card-row .label {
  display: flex;
  align-items: center;
  gap: 10px;
  color: var(--text-primary);
  font-weight: 300;
  font-size: 16px;
  line-height: 21px;
}
.App-card-row .value {
  display: flex;
  flex-direction: column;
  font-weight: 300;
  font-size: 16px;
  line-height: 21px;
}
.App-card-row .value .value-price {
  color: var(--text-light);
}
.App-card-row.inner {
  padding-left: 1.5rem;
}
.App-card-row .icon {
  vertical-align: baseline;
  margin-left: 0.31rem;
  position: relative;
  top: 2px;
}

.App-card-row > div:last-child {
  text-align: right;
}

.App-card-options {
  padding: 20px 0 0 0;
  margin-top: auto;
  display: flex;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
}

.Toastify {
  font-size: var(--font-sm);
}

.Toastify__toast {
  min-height: 4.65rem;
  cursor: default;
}

.Toastify__toast-body {
  padding: 0 0.465rem;
}

.Toastify-debug {
  margin-top: 0.465rem;
  font-size: 1.24rem;
  opacity: 0.7;
}

.Toastify-debug-button {
  display: inline-block;
  border-bottom: 1px dashed;
  cursor: pointer;
}

.Toastify-debug-content {
  margin-top: 4px;
  padding-bottom: 8px;
  max-width: 300px;
  overflow-x: auto;
}

.App-icon {
  vertical-align: middle;
  margin-bottom: 0.465rem;
}

.App-background-side-1 {
  z-index: 1;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  pointer-events: none;
  background: linear-gradient(90deg, rgba(45, 66, 252, 0.05) 0%, rgba(8, 8, 10, 0) 100%);
}

.App-background-side-2 {
  z-index: 2;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  pointer-events: none;
  background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 216, 240, 0.01) 51%, rgba(0, 216, 240, 0.03) 100%);
}

.App-background {
  z-index: 4;
  position: absolute;
  left: -100%;
  right: 0%;
  top: -20%;
  bottom: -200%;
  pointer-events: none;
  background: radial-gradient(circle, rgba(80, 10, 245, 0.05) 0%, rgba(80, 10, 245, 0.02) 36%, rgba(8, 8, 10, 0) 100%);
}

.App-background-ball-1 {
  z-index: 3;
  position: absolute;
  left: 0%;
  right: 0%;
  top: -50%;
  bottom: -200%;
  pointer-events: none;
  background: radial-gradient(circle, rgba(102, 56, 217, 0.05) 0%, rgba(56, 33, 105, 0.02) 36%, rgba(8, 8, 10, 0) 100%);
}

.App-background-ball-2 {
  z-index: 2;
  position: absolute;
  left: -50%;
  right: -150%;
  top: -30%;
  bottom: -200%;
  pointer-events: none;
  background: radial-gradient(circle, rgba(7, 149, 250, 0.05) 0%, rgba(7, 149, 250, 0.02) 36%, rgba(8, 8, 10, 0) 100%);
}

.App-highlight {
  z-index: 0;
  position: absolute;
  left: 0%;
  right: -100%;
  top: -100%;
  bottom: 0%;
  pointer-events: none;
}

.App-title {
  text-align: center;
  font-size: 4.65rem;
  margin-top: 17vh;
  margin-bottom: 1.5rem;
  font-weight: bold;
}

.App-subtitle {
  text-align: center;
  font-size: 1.7rem;
  margin-bottom: 7.75rem;
  opacity: 0.9;
  padding: 0 1.5rem;
}

.App-info-row {
  display: grid;
  grid-template-columns: auto auto;
  font-size: var(--font-sm);
  margin-bottom: 0.465rem;
}

.App-info-label {
  opacity: 0.7;
}

.App-cta {
  text-align: center;
  cursor: pointer;
  display: inline-block;
  padding: 1.5rem;
  border: none;
  font-size: var(--font-md);
  font-weight: 700;
  border-radius: 60rem;
}

a.App-cta,
button.App-cta {
  color: var(--btn-text);
  text-decoration: none;
  background: var(--btn-bg);
}

.App-cta.small {
  padding: 0.9rem 1.1rem;
}

a.App-cta.transparent,
button.App-cta.transparent {
  box-shadow: none;
  background: none;
  color: rgba(255, 255, 255, 0.7);
  opacity: 1;
}

a.App-cta.transparent:hover,
button.App-cta.transparent:hover:enabled {
  color: white;
  box-shadow: none;
  background: none;
}

button.App-cta.option,
a.App-cta.option {
  padding: 0.8rem 1.5rem;
  font-size: var(--font-sm);
}

a.App-button-option,
button.App-button-option {
  flex-grow: 1;
  background: var(--btn-bg);
  border-radius: 4px;
  font-size: var(--font-sm);
  line-height: 2rem;
  text-align: center;
  font-weight: 700;
  letter-spacing: 0px;
  color: var(--btn-text);
  padding-left: 1.6rem;
  padding-right: 1.6rem;
  text-decoration: none;
  box-sizing: border-box;
  position: relative;
  min-height: 4.2rem;
  border-radius: 10rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  border: none;
  appearance: none;
  transition: all 0.3s;
}

a.App-button-option:hover,
button.App-button-option:hover:enabled {
  opacity: 0.8;
}

a.App-button-option:active,
button.App-button-option:active:enabled {
  opacity: 0.8;
}

button.App-button-option:disabled {
  cursor: not-allowed;
}

.App-settings-row {
  margin-bottom: 0.8rem;
  font-size: var(--font-base);
}

.App-settings .App-cta {
  margin-top: 1.5rem;
}

.App-slippage-tolerance-input-container {
  position: relative;
}

.App-slippage-tolerance-input {
  border: 1px solid rgba(255, 255, 255, 0.1);
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
  width: 100%;
}

.App-slippage-tolerance-input-percent {
  position: absolute;
  right: 1.1rem;
  width: 3.1rem;
  top: 2.325rem;
  bottom: 0;
  text-align: right;
}

.Network-icon {
  vertical-align: middle;
  width: 1.85rem;
  height: 1.85rem;
}

.App-content {
  position: static !important;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.App-footer {
  left: 0;
  right: 0;
  position: fixed;
  bottom: 5vh;
  text-align: center;
}

a.App-social-link {
  margin-left: 1.1rem;
  margin-right: 1.1rem;
  font-size: 2.635rem;
  color: white;
  opacity: 0.8;
}

a.App-social-link:hover {
  opacity: 0.9;
}

.App-input {
  text-align: center;
  display: inline-block;
  width: 90%;
  max-width: 46.5rem;
}

.App-input input {
  width: 100%;
}

.App-input-top {
  display: grid;
  grid-template-columns: auto auto;
  margin-bottom: 0.8rem;
  padding-left: 0.155rem;
  padding-right: 0.465rem;
}

.App-input-label {
  text-align: left;
  opacity: 0.9;
}

.App-input-max {
  text-align: right;
  opacity: 0.9;
  cursor: pointer;
}

.App-input-max:hover {
  opacity: 1;
}

.App-input-container {
  border: 2px solid rgba(48, 31, 130, 0.8);
  background: rgba(0, 0, 0, 0.1);
  display: grid;
  grid-template-columns: 1fr auto;
}

.App-input-unit {
  text-align: right;
  padding: 1.25rem;
  font-size: 2rem;
}

button.App-button {
  text-align: center;
  display: inline-block;
  font-size: 1.7rem;
  width: 90%;
  max-width: 46.5rem;
}

.Toastify__toast--error {
  background: linear-gradient(
    rgba(255, 255, 255, 0.08) 0%,
    rgba(255, 255, 255, 0.08) 49.48%,
    rgba(211, 211, 211, 0.08) 69.79%,
    rgba(255, 255, 255, 0.08) 100%
  );
  color: white;
  border-radius: 0.6rem;
}

.Toastify__toast--success {
  background: transparent linear-gradient(90deg, #19836c 0%, #177c66 100%) 0% 0% no-repeat padding-box;
  color: white;
  box-shadow: 0px 0.3rem 0.6rem #0000005c;
  border-radius: 5px;
}

.Toastify__close-button {
  align-self: flex-start;
  border-radius: 0.4rem;
  width: 2.1rem;
  min-width: 2.1rem;
  height: 2rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  transition: none;
}
.Toastify__close-button:hover {
  background: #ffffff47 0% 0% no-repeat padding-box;
}
.Toastify__toast-body {
  line-height: 2rem;
}

.dropdown-backdrop {
  position: fixed;
  z-index: 10;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  backdrop-filter: blur(2rem);
}

@keyframes zoomIn {
  from {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  50% {
    opacity: 1;
  }
}

.zoomIn {
  animation-name: zoomIn;
}

@keyframes zoomOut {
  from {
    opacity: 1;
  }

  50% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  to {
    opacity: 0;
  }
}

.zoomOut {
  animation-name: zoomOut;
}

.positive {
  color: #118b64;
}

.positive-background {
  background: linear-gradient(45deg, rgba(10, 115, 75, 1) 0%, rgba(12, 154, 100, 1) 100%);
}

.negative {
  color: #fa3c58;
}

.negative-background {
  background: linear-gradient(45deg, rgba(115, 30, 43, 1) 0%, rgba(174, 43, 63, 1) 100%);
}

.App-warning {
  padding: 1rem 3rem;
  background: var(--section-bg);
  border-radius: 2rem;
  color: #e2726b;
  text-align: center;
}

.App-warning a {
  font-weight: 500;
  color: #e2726b;
}

.App-slider .rc-slider-rail {
  background: var(--border-light);
}

.App-slider .rc-slider-track {
  background: white;
}

.App-slider .rc-slider-dot {
  border: none;
  border-radius: 1px;
  width: 2px;
  margin-left: -1px;
  background: var(--border-light);
}

.App-slider .rc-slider-dot-active {
  background: var(--border-light);
}

.App-slider .rc-slider-handle {
  background: #d9d9d9;
  border: solid 2px #d9d9d9;
}

.App-slider .rc-slider-handle:active {
  box-shadow: 0 0 0.4rem 0.6rem rgba(45, 66, 252, 0.2);
}

.App-slider .rc-slider-mark-text,
.App-slider .rc-slider-mark-text-active {
  color: white;
}

.App-slider .rc-slider-mark-text:hover,
.App-slider .rc-slider-mark-text-active:hover {
  opacity: 0.8;
  transition: opacity 0.3s;
}

.rc-slider-tooltip {
  z-index: 5;
}

.rc-slider-tooltip-inner {
  box-shadow: none;
  padding: 0.465rem 0.8rem;
  background: #303652;
}

.rc-slider-tooltip-placement-top .rc-slider-tooltip-arrow {
  border-top-color: #303652;
}

.header-connect-btn {
  background: #3b43e3;
}

.header-connect-btn:hover {
  background: #6a70ea;
}

.header-connect-btn:focus {
  background: #7a7fec;
  border: 1px solid #3b43e3;
  box-sizing: border-box;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.header-connect-btn:active {
  background: #7a7fec;
}

.short-connect-btn {
  display: none !important;
}

.selector-backdrop {
  opacity: 1;
  height: 100vh;
  z-index: 2;
  backdrop-filter: blur(1.7rem);
  position: fixed;
  top: 0;
  width: 100%;
}

.Tab-title-section {
  padding: 3.1rem 0 0;
  margin-bottom: 2.4rem;
}

.Home-language-menu {
  display: flex;
  align-items: center;
}

@media (max-width: 1033px) {
  .long-connect-btn {
    display: none !important;
  }

  .short-connect-btn {
    display: flex !important;
  }

  button.App-connect-wallet {
    margin-top: 0.465rem;
    margin-bottom: 0.465rem;
  }
  .App-cta.small {
    padding: 0.9rem 0.8rem;
  }
  .App-subtitle {
    margin-bottom: 4.65rem;
  }

  .Page-title-section {
    margin-top: 3.1rem;
  }

  .App-card-long-content.card-list {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 1000px) {
  .App-card-long-content {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-row-gap: 1.5rem;
  }

  .App-card-divider-vertical {
    display: none;
  }

  .App-card-long_sub {
    width: 100%;
  }
}

@media (max-width: 700px) {
  .App-card-long-content {
    grid-template-columns: 1fr 1fr;
  }

  .App-card {
    padding: 2rem;
  }

  .App-card-content-row {
    flex-direction: column;
    align-items: stretch;
  }

  .App-card-title {
    font-size: 20px;
    min-height: 35px;
  }
}

@media (max-width: 600px) {
  .App-card-long-content.card-list {
    grid-template-columns: 1fr;
  }

  .App-card-long-content {
    grid-template-columns: 1fr;
  }
  .default-container {
    padding-left: 1.6rem;
    padding-right: 1.6rem;
  }
}

@media (max-width: 500px) {
  .header-connect-btn {
    margin-right: 0.4rem;
  }
}

@media (max-width: 450px) {
  .Home-faqs-content {
    padding-left: 1.5rem;
  }

  .header-connect-btn,
  .header-connect-btn:active {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .header-connect-btn:focus {
    padding-left: 0.4rem;
    padding-right: 0.4rem;
  }
}

.walletconnect-modal__mobile__toggle a {
  color: #000;
}
.walletconnect-modal__base {
  max-height: 100%;
}
.walletconnect-qrcode__image {
  max-height: 50vh;
}

* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.spin {
  animation: spin-animation infinite 1.2s linear;
}

@keyframes spin-animation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.App-more-link {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  gap: 6px;
  padding: 13px 24px;

  font-size: 13px;
  font-weight: 400;
  line-height: 17px;
  color: var(--text-primary);
  background: var(--section-bg);
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
  border-radius: 30px;
  text-decoration: none;
  transition: color 0.3s;
}

.App-more-link:hover {
  color: var(--text-light);
}

.App-more-icon {
  width: 18px;
  height: 18px;
}

.App-more-icon path {
  stroke: currentColor;
  stroke-width: 1.5px;
}
