.wrong-network-btn {
  background: #e2726b;
  padding: 1.2rem 2.4rem;
  display: inline-flex;
  align-items: center;
  border: none;
  color: #ffffff;
  font-size: var(--font-sm);
  cursor: pointer;
  border: none;
  border-radius: 22px;

  .btn-icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    fill: #ffffff !important;
  }
  .btn-label {
    font-weight: 700;
    font-size: 15px;
    line-height: 1;
    margin-left: 0.8rem;
    letter-spacing: 0;
  }
}

@media (max-width: 600px) {
  .wrong-network-btn {
    padding: 7px 14px;
  }

  .wrong-network-btn .btn-label {
    font-size: 14px;
  }
}
