.LeaderBoardTable {
  width: 100%;
  display: flex;
}

.LeaderBoardTable-table {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 25px;
  border-radius: 10px;
  background: linear-gradient(
    180deg,
    rgba(54, 61, 77, 0.25) 0%,
    rgba(49, 56, 69, 0.25) 49.48%,
    rgba(43, 48, 60, 0.25) 69.79%,
    rgba(54, 61, 77, 0.25) 100%
  );
}
.LeaderBoardTable-table td {
  padding: 0;
}
.LeaderBoardTable-table-header {
  padding-bottom: 35px;
  margin-bottom: 35px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}

.LeaderBoardTable-header-row {
  display: grid;
  grid-template-columns: 3.5fr 1fr 1fr 1fr 1fr 1fr;
  gap: 10px;

  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #c7c7c7;
}

.LeaderBoardTable-header-btn {
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 0;
  margin: 0;

  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #c7c7c7;
  background: transparent;
  border: none;
  transition: color 0.3s;
}

.LeaderBoardTable-header-btn.is-active {
  color: var(--text-primary);
}
.LeaderBoardTable-header-icon {
  width: 16px;
  height: 16px;
  transition: transform 0.3s;
}
.LeaderBoardTable-header-btn.is-active .LeaderBoardTable-header-icon {
  transform: rotate(180deg);
}

.LeaderBoardTable-header-cell {
  display: flex;
  justify-content: flex-end;
}

.LeaderBoardTable-header-cell:first-child {
  justify-content: flex-start;
}

.LeaderBoardTable-header-btn:hover {
  color: var(--text-primary);
}

.LeaderBoardTable-table-body {
  display: flex;
  flex-direction: column;
  gap: 35px;
}

.LeaderBoardTable-row {
  display: grid;
  grid-template-columns: 3.5fr 1fr 1fr 1fr 1fr 1fr;
  gap: 10px;

  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: var(--text-primary);
}

.LeaderBoardTable-cell {
  display: flex;
  justify-content: flex-end;
}

.LeaderBoardTable-cell:first-child {
  justify-content: flex-start;
}

.LeaderBoardTable-cell-row {
  display: flex;
  align-items: center;
  gap: 10px;
}

.LeaderBoardTable-cell-logo {
  width: 24px;
  height: 24px;
  border-radius: 50%;
}

.LeaderBoardTable-cell-name {
  width: 100%;
  max-width: 360px;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 700;
}

@media screen and (max-width: 1200px) {
  .LeaderBoardTable-header-row,
  .LeaderBoardTable-row {
    grid-template-columns: 2fr 1fr 1fr 1fr 1fr 1fr;
  }

  .LeaderBoardTable-cell-name {
    max-width: 200px;
  }
}

@media screen and (max-width: 1033px) {
  .LeaderBoardTable {
    display: none;
  }
}
