.Page-header-box {
  display: flex;
  align-items: center;
  gap: 30px;
  width: 100%;
  padding: 30px;
  border-radius: 20px;
  background: var(--section-bg);
  margin: 0 auto;
  font-family: inherit;
}

.Page-header-col {
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex-grow: 1;
}

.Page-heading {
  margin: 0;
  font-weight: 700;
  font-size: 30px;
  line-height: 39px;
}

.Page-description {
  margin: 0;
  font-weight: 400;
  font-size: 15px;
  line-height: 17px;
}

.Page-header-link {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  gap: 6px;
  padding: 13px 24px;

  font-weight: 700;
  font-size: 15px;
  line-height: 17px;
  color: var(--btn-text);
  background: #c7c7c7;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
  border-radius: 30px;
  text-decoration: none;
  transition: background-color 0.3s;
}

.Page-header-link:hover {
  background: rgba(255, 255, 255, 0.9);
}

.Page-header-icon {
  width: 18px;
  height: 18px;
}

.Page-header-icon path {
  stroke: currentColor;
  stroke-width: 1.5px;
}

@media screen and (max-width: 600px) {
  .Page-header-box {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    padding: 20px;
  }

  .Page-header-col {
    gap: 10px;
  }

  .Page-header-link {
    width: 100%;
  }

  .Page-heading {
    font-size: 24px;
  }
}
