.LeaderBoardCard {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.LeaderBoardCard-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.LeaderBoardCard-sub-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.LeaderBoardCard-leverage {
  color: #c7c7c7;
}

.LeaderBoardCard-value {
  display: flex;
  align-items: center;
}

.LeaderBoardCard-price {
  margin-left: 5px;
}

.Tooltip-handle {
  cursor: help;
  position: relative;
  display: inline-flex;
  text-decoration: underline;
  text-decoration-style: dashed;
  text-decoration-thickness: 1px;
  text-underline-offset: 2px;
  text-decoration-color: rgba(255, 255, 255, 0.6);
}
