/* Block */

.Tab.block {
  display: grid;
  gap: 15px;
  grid-auto-flow: column;
  font-size: var(--font-sm);
  border-radius: 3px;
  overflow: hidden;
  color: var(--text-primary);
  background: transparent;
  box-shadow: inset 0px 0px 30px 5px rgba(255, 255, 255, 0.01);
}

.Tab-option-icon {
  margin-right: 0.4rem;
}

.Tab.block .Tab-option {
  text-align: center;
  padding: 0.8rem;
  border-radius: 1.2rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  cursor: pointer;
  font-weight: 700;
  border: 1px solid rgba(255, 255, 255, 0.1);
  transition: all 0.3s;
}

.Tab.block .Tab-option:hover {
  border-color: white;
  color: var(--text-primary);
}

.Tab.block .Tab-option.active {
  opacity: 1;
  pointer-events: none;
  background: #FFFFFF;
  color: #39404E;
  border-color: white;
}

.Tab.block .Tab-option.active .Tab-option-icon {
  opacity: 1;
}

/* Inline */

.Tab.inline .Tab-option {
  cursor: pointer;
  display: inline-block;
  margin-right: 1.5rem;
  font-size: var(--font-base);
}

.Tab.inline .Tab-option:hover {
  opacity: 0.8;
}

.Tab.inline .Tab-option.active {
  opacity: 1;
  pointer-events: none;
  font-weight: 700;
}

/* Group */

.Tab.group {
  display: flex;
  width: 100%;
  align-items: center;
  font-size: var(--font-md);
  overflow: hidden;
  color: var(--text-primary);
  background: transparent;
}

.Tab.group .Tab-option {
  text-align: center;
  padding: 1rem;
  flex-grow: 1;
  cursor: pointer;
  font-weight: 700;
  line-height: 17px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  transition: all 0.3s;
}
.Tab.group .Tab-option:first-child {
  border-radius: 12px 0 0 12px;
}

.Tab.group .Tab-option:last-child {
  border-radius: 0 12px 12px 0;
}
.Tab.group  .Tab-option-icon {
  margin-right: 10px;
}

.Tab.group .Tab-option:hover {
  border-color: white;
  color: var(--text-primary);
}

.Tab.group .Tab-option.active {
  opacity: 1;
  pointer-events: none;
  background: #FFFFFF;
  color: #39404E;
  border-color: white;
}

.Tab.group .Tab-option.active .Tab-option-icon {
  opacity: 1;
}