.App-header-backdrop {
  position: fixed;
  z-index: 10;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.App-header.large {
  position: relative;
  display: grid;
  grid-template-columns: 160px auto 160px;
  gap: 10px;
  padding: 20px 10px;
}

.App-header-container-left {
  display: flex;
  align-items: center;
  width: 100%;
}

.App-header a.App-header-link-main,
.App-header .App-header-link-main {
  display: flex;
  align-items: center;
  width: 100%;
}

.App-header-link-main img {
  display: inline-block;
  width: 100%;
  margin-top: 6px;
}

.App-header-link-main img.small {
  display: none;
}
.App-header-container-center {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 10px;
  margin: 0 auto;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 50px;
}
.App-header.large .App-header-links {
  display: flex;
}

.App-header.small {
  display: none;
}

.App-header-link-home {
  display: none;
}

.App-header-links-header {
  height: 6.2rem;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 3;
}

.App-header-top {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: space-between;
}

.App-header-menu-icon-block {
  margin-right: 1.2rem;
  margin-left: 0px;
  display: flex;
  align-items: center;
}

.App-header-menu-icon {
  color: white;
  font-size: 2rem;
  cursor: pointer;
  opacity: 0.7;
  margin: 0.9rem 1rem;
}

.App-header-menu-icon:hover {
  opacity: 0.9;
}

.App-header-container-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  min-width: 100px;
}

.App-header-token {
  margin-right: 30px;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 0;

  font-weight: 500;
  color: var(--text-primary);
  font-size: 13px;
  line-height: 17px;
  background: transparent;
  border: none;
}

.App-header-token-logo {
  width: 17px;
  height: 17px;
}

.App-header-user {
  display: flex;
  align-items: center;
}

.App-header-user-settings {
  display: inline-flex;
  cursor: pointer;
  border: none;
  border-radius: 1.5rem;
  font-size: var(--font-base);
  background: none;
  color: white;
  padding: 0;
  width: 2.4rem;
  height: 2.4rem;
  align-items: center;
  border-radius: 0.4rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.App-header-user-netowork {
  margin-right: 2.4rem;
}

.App-header-user-link {
  margin-right: 2.4rem;
}

.App-header-user-address {
  border: 1px solid var(--text-primary);
  border-radius: 22px;
  display: inline-flex;
  align-items: center;
  color: white;
  position: relative;
}

.App-header-drawer {
  background: linear-gradient(
    rgba(255, 255, 255, 0.08) 0%,
    rgba(255, 255, 255, 0.08) 49.48%,
    rgba(211, 211, 211, 0.08) 69.79%,
    rgba(255, 255, 255, 0.08) 100%
  );
  box-shadow: 0.8rem 0.3rem 0.6rem #00000029;
  opacity: 1;
  backdrop-filter: blur(2.7rem);
  position: fixed;
  z-index: 801;
  left: 0;
  right: 0;
  top: 0;
  height: 100vh;
  width: 30.4rem;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.App-header-drawer .App-header-links {
  flex-direction: column;
  align-items: stretch;
}
.App-header-drawer::-webkit-scrollbar {
  display: none;
}

.App-header-drawer .App-header-link-container .a,
.App-header-drawer .App-header-link-container a {
  font-size: var(--font-sm);
  line-height: 1.8rem;
  font-weight: normal;
  letter-spacing: 0.1px;
  color: var(--text-primary);
  padding: 1.5rem 1.6rem;
  text-decoration: none;
  border-radius: 0;
  gap: 20px;
}

.App-header-drawer .App-header-link-container .a:hover::after,
.App-header-drawer .App-header-link-container .a:focus::after,
.App-header-drawer .App-header-link-container .a:active::after,
.App-header-drawer .App-header-link-container a:hover::after,
.App-header-drawer .App-header-link-container a:focus::after,
.App-header-drawer .App-header-link-container a.active::after {
  border-radius: 0;
  background-color: rgba(255, 255, 255, 0.2);
}

@media (max-width: 1320px) {
  .App-header-container-center {
    gap: 4px;
  }

  .App-header.large {
    grid-template-columns: 160px 1fr;
  }

  .App-header-container-right {
    display: none;
  }

  .App-header-container-center {
    margin: 0 0 0 auto;
  }
}

@media (max-width: 1100px) {
  .App-header-user-address {
    padding: 0 0px;
  }

  .App-header-user-link {
    margin-right: 1.6rem;
  }

  .App-header-user-netowork {
    margin-right: 1.6rem;
  }

  .App-header-link-home {
    display: block;
  }

  .App-header-user {
    padding-bottom: 0;
  }

  .App-header-container-right {
    display: flex;
    align-items: center;
    min-width: auto;
  }

  .App-header-link-desktop {
    display: none;
  }

  .App-header {
    grid-template-columns: 1fr auto auto auto auto;
  }

  .App-header-link-main img {
    width: 100%;
    max-width: 160px;
  }

  .App-header.large {
    display: none;
  }

  .App-header.small {
    height: 6.2rem;
    display: flex;
    justify-content: space-between;
    position: relative;
    padding-left: 1.6rem;
    padding-right: 1.6rem;
  }

  a.App-header-user-account {
    margin-right: 0;
  }
}

@media (max-width: 600px) {
  .App-header-link-main img.big {
    display: none;
  }

  .App-header-link-main img.small {
    display: flex;
    width: 35px;
    height: 35px;
    margin: 0;
  }
}

@media (max-width: 450px) {
  .App-header-user-link {
    margin-right: 0.6rem;
  }

  .App-header-user-netowork {
    margin-right: 0.6rem;
  }

  .App-header-menu-icon-block {
    margin-left: 0;
    margin-right: 0.4rem;
  }

  .App-header-menu-icon {
    margin: 0.4rem 0.5rem;
  }

  .App-header.small {
    padding-left: 0.6rem;
    padding-right: 0.6rem;
  }

  .App-header .App-header-link-main {
    padding: 0.155rem !important;
    margin-left: 0rem !important;
  }
}
