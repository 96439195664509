.Modal {
  position: fixed;
  z-index: 1001;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: left;
}

.Modal-scrollable .Modal-content .Modal-body {
  overscroll-behavior: smooth;
  max-height: calc(80vh - 5.5rem);
  overflow-y: auto;
  padding-right: 5px;
}

.Modal-backdrop {
  position: fixed;
  z-index: 10;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 4, 16, 0.6);
  backdrop-filter: blur(20px);
}

.Modal-content {
  z-index: 20;
  position: relative;
  max-width: 100%;
  max-height: 90vh;
  width: calc(100% - 32px);
  max-width: 453px;
  overflow: auto;
  background: linear-gradient(
    rgba(255, 255, 255, 0.08) 0%,
    rgba(255, 255, 255, 0.08) 49.48%,
    rgba(211, 211, 211, 0.08) 69.79%,
    rgba(255, 255, 255, 0.08) 100%
  );
  border-radius: 20px;
}

.divider {
  display: none;
}

.Modal.non-scrollable .Modal-content {
  overflow: visible;
}

.Modal-title-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem;
  font-weight: 700;
  background: linear-gradient( rgba(255, 255, 255, 0.08) 0%, rgba(255, 255, 255, 0.08) 49.48%, rgba(211, 211, 211, 0.08) 69.79%, rgba(255, 255, 255, 0.08) 100% );
}

.Modal-body {
  margin: 2rem;
  display: flex;
  flex-direction: column;
}
.Modal-body::-webkit-scrollbar {
  width: 0.6rem;
}

.Modal-body::-webkit-scrollbar-track {
  background-color: #1c1c1c;
  border-radius: 155rem;
}

.Modal-body::-webkit-scrollbar-thumb {
  background-color: #949393;
  border-radius: 155rem;
}

.Modal-title {
  text-align: left;
  font-size: var(--font-md);
  font-weight: 500;
  line-height: 1;
}

.Modal-close-button {
  text-align: right;
}

.Modal-close-icon {
  opacity: 0.6;
  cursor: pointer;
  text-align: right;
  display: inline-block;
}

.Modal-close-icon:hover {
  opacity: 0.9;
}

.Modal-note {
  margin-bottom: 1.5rem;
  margin-top: 0.8rem;
}
