.BuyCrypto.page-layout {
  width: 100%;
  align-items: center;
}

.BuyCrypto .section-title-block {
  justify-content: center;
}

.BuyCrypto-heading {
  font-size: 30px;
  line-height: 39px;
  font-weight: 700;
  color: #ffffff;
}

.BuyCrypto-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 900px;
  min-height: 667px;
}

.BuyCrypto-content > div {
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 900px) {
  .BuyCrypto-content {
    min-width: auto;
  }
}
