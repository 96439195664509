.LeaderBoard {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.LeaderBoard-header-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 20px;
  gap: 20px;
  padding: 30px;
  background: linear-gradient(
    180deg,
    rgba(54, 61, 77, 0.25) 0%,
    rgba(49, 56, 69, 0.25) 49.48%,
    rgba(43, 48, 60, 0.25) 69.79%,
    rgba(54, 61, 77, 0.25) 100%
  );
  border-radius: 20px;
}

.LeaderBoard .section-title-block {
  margin: 0;
}

.LeaderBoard-tab-container {
  width: 100%;
  max-width: 277px;
}

.LeaderBoard-content-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
}

.LeaderBoard-filter-tabs {
  max-width: 50%;
}

.LeaderBoard-mobile-list {
  display: none;
}

@media screen and (max-width: 1033px) {
  .LeaderBoard-mobile-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    list-style: none;
    padding: 24px;
    margin: 0;
    border-radius: 10px;
    background: linear-gradient(
      180deg,
      rgba(54, 61, 77, 0.25) 0%,
      rgba(49, 56, 69, 0.25) 49.48%,
      rgba(43, 48, 60, 0.25) 69.79%,
      rgba(54, 61, 77, 0.25) 100%
    );
  }
  .LeaderBoard-mobile-item {
    display: flex;
    padding: 0 0 24px;
    margin: 0 0 24px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  }

  .LeaderBoard-mobile-item:last-child {
    border-bottom: 0;
    padding: 0;
    margin: 0;
  }
}

@media screen and (max-width: 700px) {
  .LeaderBoard {
    gap: 20px;
  }
  .LeaderBoard-header-box {
    padding: 20px;
    flex-direction: column;
    gap: 30px;
  }
  .LeaderBoard-tab-container {
    max-width: 100%;
  }
  .LeaderBoard-filter-tabs.Tab.group {
    font-size: var(--font-sm);
  }
  .LeaderBoard-filter-tabs {
    max-width: 100%;
  }
}
